@import "~jsoneditor/dist/jsoneditor.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Barlow Condensed';
  src: local('Barlow Condensed'), url(./assets/fonts/BarlowCondensed-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow Condensed Medium';
  src: local('Barlow Condensed Medium'), url(./assets/fonts/BarlowCondensed-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: local('Barlow Semi Condensed'), url(./assets/fonts/BarlowSemiCondensed-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow Semi Condensed Light';
  src: local('Barlow Semi Condensed Light'), url(./assets/fonts/BarlowSemiCondensed-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow Semi Condensed Medium';
  src: local('Barlow Semi Condensed Medium'), url(./assets/fonts/BarlowSemiCondensed-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow Semi Condensed SemiBold';
  src: local('Barlow Semi Condensed SemiBold'), url(./assets/fonts/BarlowSemiCondensed-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Serif Italic';
  src: local('Noto Serif'), url(./assets/fonts/NotoSerif-Italic.woff2) format('woff2');
}


:root {
  // Common container width
  --app-main-container-width: 1150px;
  // Common colors
  --app-color-darkblue: #003854;
  --app-color-darkblue2: #668898;
  --app-color-darkblue3: #809caa;
  --app-color-lightblue: #008caf;
  --app-color-red: #d90051;
  --app-color-darkcyan: #23a08c;
  --app-color-green: #76b82a;
  --app-color-yellow: #d28c23;
  --app-color-warning: #ec732c;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  width: var(--app-main-container-width);
  margin: 0 auto;
}

input {
  -webkit-appearance: textfield;
}

select {
  background-color: white;
  border-radius: 0;
}

.snackbar-custom {
  --mdc-snackbar-container-color: var(--app-color-darkblue);
  --mdc-snackbar-supporting-text-color: #fff;
  --mat-snack-bar-button-color: #fff;

  // style for message
  & .mdc-snackbar__label {
    font-family: 'Barlow Semi Condensed Medium', sans-serif;
  }

  // styles for action button
  & .mdc-button__label {
    font-weight: 600;
  }

}

.snackbar-error {
  --mdc-snackbar-container-color: var(--app-color-red);
  --mdc-snackbar-supporting-text-color: #fff;
  --mat-snack-bar-button-color: #fff;

  // style for message
  & .mdc-snackbar__label {
    font-family: 'Barlow Semi Condensed Medium', sans-serif;
  }

  // styles for action button
  & .mdc-button__label {
    font-weight: 600;
  }

}

.custom-checkbox-color {
  & .mdc-checkbox__background {
    background-color: var(--app-color-darkcyan) !important;
    border-color: var(--app-color-darkcyan) !important;
  }
}


